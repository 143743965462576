import React from "react"
import axios from "axios";
import Sidebar from "src/component/layout/sidebar";
import Layout from "src/component/layout/layout"
import Loader from "src/component/common/loader"
import FormLower from "src/component/common/formLower"
import { Seo } from "src/component/common/seo"
import StickySidebar from "sticky-sidebar"
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useLocation } from "@reach/router"
import { graphql, Link } from "gatsby"

export default function Search({ data }) {
    const url = useLocation()
    const ref = React.useRef(true);
    const [infos, setInfos] = React.useState([]);
    const [action, setAction] = React.useState(false);
    const [total, setTotal] = React.useState(0);
    const [isAll, setIsAll] = React.useState(false);
    const [offset, setOffset] = React.useState(0);
    const [displaySort, setDisplaySort] = React.useState(false);

    const params = Object.fromEntries(new URLSearchParams(useLocation().search));
    const [keyword, setKeyword] = React.useState("");
    const [checkedPostCategories, setCheckedPostCategories] = React.useState([])
    const [checkedTypeCategories, setCheckedTypeCategories] = React.useState([])
    const [checkedUsesCategories, setCheckedUsesCategories] = React.useState([])
    const [checkedCasestudyCategories, setCheckedCasestudyCategories] = React.useState([])
    const [currentSort, setCurrentSort] = React.useState('');
    const pagingLength = 10;

    const searchInfos = async (isTransition, isMore) => {

        let searchQuery = {}
        let categoriesQuery = []
        let categoriesQueryParams = ''
        let triggerUrl = ''

        // 検索ページ外からの遷移の場合URLクエリパラメーターからソートするので
        // その処理の切り替えをおこなっている
        if(isTransition){
            // ページ外からの遷移の場合
            // URLクエリパラメーターを-で区切った配列を格納している
            if(params.q)  searchQuery.q = decodeURI(params.q);
            if(params.pc) params.pc.split('-').forEach(category => { categoriesQuery.push(`post_category[contains]${category}`); });
            if(params.tc) params.tc.split('-').forEach(category => { categoriesQuery.push(`type_category[contains]${category}`); });
            if(params.uc) params.uc.split('-').forEach(category => { categoriesQuery.push(`uses_category[contains]${category}`); });
            if(params.cc) params.cc.split('-').forEach(category => { categoriesQuery.push(`casestudy_category[contains]${category}`); });
        } else {
            if(keyword) {
                searchQuery.q = decodeURI(keyword);
            }
            if(checkedPostCategories.length) {
                checkedPostCategories.forEach(value => {
                    categoriesQuery.push(`post_category[contains]${value}`);
                });
            }
            if(checkedTypeCategories.length) {
                checkedTypeCategories.forEach(value => {
                    categoriesQuery.push(`type_category[contains]${value}`);
                });
            }
            if(checkedUsesCategories.length) {
                checkedUsesCategories.forEach(value => {
                    categoriesQuery.push(`uses_category[contains]${value}`);
                });
            }
            if(checkedCasestudyCategories.length) {
                checkedCasestudyCategories.forEach(value => {
                    categoriesQuery.push(`casestudy_category[contains]${value}`);
                });
            }
        }

        categoriesQuery.forEach(function(category, idx, array){
            if(idx === array.length - 1){
                categoriesQueryParams += `${category}`
            } else {
                categoriesQueryParams += `${category}[and]`
            }
        });

        if(isMore) {
            searchQuery.filters = currentSort
        } else {
            setCurrentSort(categoriesQueryParams)
            searchQuery.filters = categoriesQueryParams
        }
        searchQuery.offset  = offset
        searchQuery.orders  = '-display_date'

        // console.log('search')
        // console.log(data.site.siteMetadata.developmentSiteUrl)
        // console.log(url.origin)
        // console.log(data.site.siteMetadata.developmentTriggerUrl)
        // console.log(data.site.siteMetadata.productionTriggerUrl)
        // console.log(data.site.siteMetadata.developmentSiteUrl.slice(0, -1) === url.origin)
        // console.log('test')

        if (data.site.siteMetadata.developmentSiteUrl.slice(0, -1) === url.origin || url.origin.match(/localhost/)){
            // console.log('dev')
            triggerUrl = data.site.siteMetadata.developmentTriggerUrl
        } else {
            // console.log('prd')
            triggerUrl = data.site.siteMetadata.productionTriggerUrl
        }

        const res = await axios.get(triggerUrl, { params:searchQuery });

        setTimeout(() => {
            offset ? setInfos(infos.concat(res.data.contents)) : setInfos(res.data.contents);
            setTotal(res.data.totalCount)
            setAction(true)
            window.dispatchEvent(new Event('resize'));
            if(!isTransition) sidebarButtonFixed();
        }, 500);
    };
    const paramSetting = () => {
        if(params.q)  setKeyword(params.q)
        if(params.pc) setCheckedPostCategories(params.pc.split('-').map(category => (category)))
        if(params.tc) setCheckedTypeCategories(params.tc.split('-').map(category => (category)))
        if(params.uc) setCheckedUsesCategories(params.uc.split('-').map(category => (category)))
        if(params.cc) setCheckedCasestudyCategories(params.cc.split('-').map(category => (category)))
    }
    const clearCheckedCategories = () => {
        setCheckedPostCategories([])
        setCheckedTypeCategories([])
        setCheckedUsesCategories([])
        setCheckedCasestudyCategories([])
    };
    const sidebarButtonAbsolute = () => {
        const sidebar_button = document.getElementById('js-sidebar-button');
        sidebar_button.style.position = 'absolute';
        sidebar_button.style.display = 'flex';
        sidebar_button.style.removeProperty('left');
        sidebar_button.style.removeProperty('width');
    };
    const sidebarButtonFixed = () => {
        const sidebar_base   = document.getElementById('js-sidebar');
        const sidebar_button = document.getElementById('js-sidebar-button');
        sidebar_button.style.width = sidebar_base.clientWidth + 'px';
        sidebar_button.style.left  = sidebar_base.getBoundingClientRect().left + 'px';
        sidebar_button.style.display = 'flex';
        sidebar_button.style.removeProperty('position');
        sidebar_button.style.removeProperty('top');
        sidebar_button.style.removeProperty('bottom');
    };
    const moreInfos = () => {
        setAction(false)
        setOffset(offset + pagingLength);
    };
    const showDisplaySort = () => {
        setDisplaySort(true);
    };
    const research = () => {
        const agent    = window.navigator.userAgent.toLowerCase()
        const duration = (agent.indexOf("chrome") !== -1) ? 800 : 600
        if (window.pageYOffset > 100) {
            scrollTo('#top');
            setTimeout(() => {
                researchProcess();
            }, duration);
        } else {
            researchProcess();
        }
    };
    const researchProcess = () => {
        setOffset(0)
        setInfos([]);
        setAction(false)
        if(offset === 0) searchInfos(false, false);
    };
    const sticky = () => {
        if ( typeof window !== 'undefined' ) {
            const sidebar_base   = document.getElementById('js-sidebar');
            const sidebar_button = document.getElementById('js-sidebar-button');
            const content        = document.getElementById('js-content');
            let sidebar;
            let timeoutID = 0;
            let delay = 300;
            if (!(window.matchMedia && window.matchMedia('(max-device-width: 1024px)').matches)) {
                sidebar_button.style.width = sidebar_base.clientWidth + 'px';
                sidebar_button.style.left  = sidebar_base.getBoundingClientRect().left + 'px';
                sidebar = new StickySidebar('.l-sidebar', {
                    topSpacing: 150,
                    bottomSpacing: 0,
                    containerSelector: '.l-main',
                    innerWrapperSelector: '.l-sidebar__inner'
                });
            }
            if((window.pageYOffset + document.documentElement.clientHeight) > (window.pageYOffset + content.getBoundingClientRect().bottom)){
                sidebarButtonAbsolute();
            } else {
                sidebarButtonFixed();
            }
            window.addEventListener("resize", () => {
                if (getComputedStyle(sidebar_button).position === 'fixed') {
                    sidebar_button.style.width = sidebar_base.clientWidth + 'px';
                    sidebar_button.style.left  = sidebar_base.getBoundingClientRect().left + 'px';
                }
                clearTimeout(timeoutID);
                timeoutID = setTimeout(()=>{
                    if(window.innerWidth < 1024) {
                        if(sidebar) sidebar.destroy();
                    } else {
                        sidebar = new StickySidebar('.l-sidebar', {
                            topSpacing: 150,
                            bottomSpacing: 0,
                            containerSelector: '.l-main',
                            innerWrapperSelector: '.l-sidebar__inner'
                        });
                    }
                }, delay);
            }, false);
            window.addEventListener("scroll", () => {
                if((window.pageYOffset + document.documentElement.clientHeight) > (window.pageYOffset + content.getBoundingClientRect().bottom)){
                    sidebarButtonAbsolute();
                } else {
                    sidebarButtonFixed();
                }
            }, false);
        }
    };
    const dateFormat = (date, separate) => {
        const base_date = new Date(date);
        const year = base_date.getFullYear();
        const month = base_date.getMonth() + 1;
        const day = base_date.getDate();
        const formatted_date = year + separate + String(month).padStart(2, '0') + separate + String(day).padStart(2, '0');
        return formatted_date;
    };
    React.useEffect(() => {
        paramSetting();
        searchInfos(true, false);
        sticky();
    }, [])
    React.useEffect(() => {
        if(ref.current) return;
        offset >= pagingLength ? searchInfos(false, true) : searchInfos(false, false);
    }, [offset])
    React.useEffect(() => {
        if (ref.current) {
            ref.current = false;
            return;
        }
        total === infos.length ? setIsAll(true) : setIsAll(false)
    }, [infos, total])
    return (
        <>
            <Layout hasSidebar displaySort={displaySort}>
                <Sidebar
                    displaySort={displaySort}
                    setDisplaySort={setDisplaySort}
                    checkedPostCategories={checkedPostCategories}
                    setCheckedPostCategories={setCheckedPostCategories}
                    checkedTypeCategories={checkedTypeCategories}
                    setCheckedTypeCategories={setCheckedTypeCategories}
                    checkedUsesCategories={checkedUsesCategories}
                    setCheckedUsesCategories={setCheckedUsesCategories}
                    checkedCasestudyCategories={checkedCasestudyCategories}
                    setCheckedCasestudyCategories={setCheckedCasestudyCategories}
                    clearCheckedCategories={clearCheckedCategories}
                    research={research}
                />
                <div className="l-content" id="js-content">
                    <div className="p-search">
                        <FormLower
                            keyword={keyword}
                            setKeyword={setKeyword}
                            research={research}
                        />
                        {
                            infos.length > 0 ? 
                                <ol className="p-search__list">
                                    { infos.map((info) => (
                                        <li key={info.id}>
                                            <Link to={`/news/${info.id}`}>
                                                <div className="p-search__list__information">
                                                    {
                                                        info.display_date ? (
                                                            <time className="p-search__list__time" dateTime={dateFormat(info.display_date, '-')}>{dateFormat(info.display_date, '.')}</time>
                                                        ) : (
                                                            <time className="p-search__list__time" dateTime={dateFormat(info.publishedAt, '-')}>{dateFormat(info.publishedAt, '.')}</time>
                                                        )
                                                    }
                                                    {
                                                        info.post_category.length ? (
                                                            <ul className="p-search__list__category">
                                                                { 
                                                                    info.post_category.map((category) => (
                                                                        <li key={category.id}>{category.name}</li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        ) : null
                                                    }
                                                </div>
                                                <p className="p-search__list__subtitle">{info.title}</p>
                                                {
                                                    (info.type_category.length || info.uses_category.length || info.casestudy_category.length) ? (
                                                        <ul className="p-search__list__tag">
                                                            {
                                                                info.type_category.length ? (
                                                                    info.type_category.map((category) => (
                                                                        <li key={category.id}>{category.name}</li>
                                                                    ))
                                                                ) : null
                                                            }
                                                            {
                                                                info.uses_category.length ? (
                                                                    info.uses_category.map((category) => (
                                                                        <li key={category.id}>{category.name}</li>
                                                                    ))
                                                                ) : null
                                                            }
                                                            {
                                                                info.casestudy_category.length ? (
                                                                    info.casestudy_category.map((category) => (
                                                                        <li key={category.id}>{category.name}</li>
                                                                    ))
                                                                ) : null
                                                            }
                                                        </ul>
                                                    ) : null
                                                }
                                            </Link>
                                        </li>
                                    ))}
                                </ol>
                            : (infos.length === 0 && action === true) ? (
                                <p className="p-search__notfound">検索結果はありませんでした。<br />検索条件を変更してください。</p>
                            ) : null
                        }
                        {
                            action === false ? (
                                <div className="p-search__progress">
                                    <Loader />
                                </div>
                            ) : null
                        }
                        <div className="p-search__controller">
                            {
                                (infos.length > 0 && action === true && !isAll) ? (
                                    <div className="p-search__controller__more"><button className="c-button03" id="js-more-infos" onClick={moreInfos}><span>さらに検索結果を表示</span></button></div>
                                ) : (
                                    <div className="p-search__controller__more is-hide"><button className="c-button03" id="js-more-infos" onClick={moreInfos}><span>さらに検索結果を表示</span></button></div>
                                )
                            }
                            <button className="p-search__controller__sort" onClick={showDisplaySort}>
                                <span className="is-icon">
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.40286 7.33471L4.02605 2H13.3392L8.96237 7.33473V13.2606L8.40286 12.771V7.33471ZM6.40286 13.6785L9.30387 16.2169C9.95045 16.7827 10.9624 16.3235 10.9624 15.4644V8.05018L16.2263 1.63429C16.7618 0.981592 16.2974 0 15.4532 0H1.91212C1.06787 0 0.603523 0.981588 1.13902 1.63428L6.40286 8.05016V13.6785Z" fill="white"/>
                                    </svg>
                                </span>
                                <span className="is-text">絞り込み</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="記事一覧"
            description=""
            pathname=""
            isNoindex
        />
    )
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                developmentSiteUrl
                developmentTriggerUrl
                productionTriggerUrl
            }
        }
    }
`