import React from "react"
import { slideToggle } from "src/component/common/slideToggle"
import { useStaticQuery, graphql } from 'gatsby'

export default function Sidebar({
    displaySort,
    setDisplaySort,
    checkedPostCategories,
    setCheckedPostCategories,
    checkedTypeCategories,
    setCheckedTypeCategories,
    checkedUsesCategories,
    setCheckedUsesCategories,
    checkedCasestudyCategories,
    setCheckedCasestudyCategories,
    clearCheckedCategories,
    research,
    data
}) {
    const handleChange = (e) => {
        switch (e.currentTarget.getAttribute('data-category-type')){
            case 'post_category':
                if(e.target.checked) setCheckedPostCategories([...checkedPostCategories, e.target.id])
                else                 setCheckedPostCategories(checkedPostCategories.filter((checkedPostCategory, index) => (checkedPostCategory !== e.target.id)))
                break;
            case 'type_category':
                if(e.target.checked) setCheckedTypeCategories([...checkedTypeCategories, e.target.id])
                else                 setCheckedTypeCategories(checkedTypeCategories.filter((checkedTypeCategory, index) => (checkedTypeCategory !== e.target.id)))
                break;
            case 'uses_category':
                if(e.target.checked) setCheckedUsesCategories([...checkedUsesCategories, e.target.id])
                else                 setCheckedUsesCategories(checkedUsesCategories.filter((checkedUsesCategory, index) => (checkedUsesCategory !== e.target.id)))
                break;
            case 'casestudy_category':
                if(e.target.checked) setCheckedCasestudyCategories([...checkedCasestudyCategories, e.target.id])
                else                 setCheckedCasestudyCategories(checkedCasestudyCategories.filter((checkedCasestudyCategory, index) => (checkedCasestudyCategory !== e.target.id)))
                break;
            default:
        }
    }
    const toggleList = (e) => {
        const type = e.currentTarget.getAttribute('data-category-type')
        let isSP = (window.matchMedia && window.matchMedia('(max-device-width: 1024px)').matches)
        let list
        let button
        if(type === 'type_category') {
            list   = document.querySelector("#type-list")
            button = document.querySelector("#type-button")
        } else if(type === 'uses_category') {
            list   = document.querySelector("#uses-list")
            button = document.querySelector("#uses-button")
        } else if(type === 'casestudy_category') {
            list   = document.querySelector("#casestudy-list")
            button = document.querySelector("#casestudy-button")
        } else {
            list   = document.querySelector("#type-list")
            button = document.querySelector("#type-button")
        }
        if (isSP) {
            slideToggle(list, 300);
            button.classList.contains('is-active') ? button.classList.remove('is-active') : button.classList.add('is-active')
        }
    };
    const hideDisplaySort = () => {
        setDisplaySort(false);
    };
    data = useStaticQuery(
        graphql`
            query {
                allMicrocmsPostCategory(filter: {postCategoryId: {ne: "case_study"}}) {
                    edges {
                        node {
                            name
                            postCategoryId
                            id
                        }
                    }
                }
                allMicrocmsTypeCategory {
                    edges {
                        node {
                            id
                            name
                            typeCategoryId
                        }
                    }
                }
                allMicrocmsUsesCategory {
                    edges {
                        node {
                            id
                            name
                            usesCategoryId
                        }
                    }
                }
                allMicrocmsCasestudyCategory {
                    edges {
                        node {
                            id
                            name
                            casestudyCategoryId
                        }
                    }
                }
            }
        `
    )
    return (
        <>
            <div className={`l-sidebar${displaySort ? ' is-active' : ''}`} id="js-sidebar">
                <div className="l-sidebar__inner" id="js-sidebar-inner">
                    <div className="l-sidebar__content">
                        <div className="l-sidebar__header">
                            <button className="l-sidebar__header__back" onClick={hideDisplaySort} aria-label="閉じるボタン">
                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z" fill="#002B5D"/>
                                </svg>
                            </button>
                            <span className="l-sidebar__header__title">絞り込み</span>
                        </div>
                        <dl className="l-sidebar__define">
                            <div className="is-category">
                                <dt>
                                    <div><span className="is-text">カテゴリ</span></div>
                                </dt>
                                <dd>
                                    <ul>
                                        {data.allMicrocmsPostCategory.edges.map(({ node }) => (
                                            <li key={node.id}>
                                                <input
                                                    id={node.postCategoryId}
                                                    value={node.postCategoryId}
                                                    name="post_category"
                                                    type="checkbox"
                                                    data-category-type="post_category"
                                                    onChange={handleChange}
                                                    checked={checkedPostCategories.includes(node.postCategoryId)}
                                                />
                                                <label htmlFor={node.postCategoryId}>{node.name}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </dd>
                            </div>
                            <div className="is-type">
                                <dt id="type-button">
                                    <button
                                        onClick={toggleList}
                                        data-category-type="type_category"
                                    >
                                        <span className="is-text">業種</span>
                                        <span className="is-icon"></span>
                                    </button>
                                </dt>
                                <dd id="type-list">
                                    <ul>
                                        {data.allMicrocmsTypeCategory.edges.map(({ node }) => (
                                            <li key={node.id}>
                                                <input
                                                    id={node.typeCategoryId}
                                                    value={node.typeCategoryId}
                                                    name="type_category"
                                                    type="checkbox"
                                                    data-category-type="type_category"
                                                    onChange={handleChange}
                                                    checked={checkedTypeCategories.includes(node.typeCategoryId)}
                                                />
                                                <label htmlFor={node.typeCategoryId}>{node.name}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </dd>
                            </div>
                            <div className="is-uses">
                                <dt id="uses-button">
                                    <button
                                        onClick={toggleList}
                                        data-category-type="uses_category"
                                    >
                                        <span className="is-text">活用シーン</span>
                                        <span className="is-icon"></span>
                                    </button>
                                </dt>
                                <dd id="uses-list">
                                    <ul>
                                        {data.allMicrocmsUsesCategory.edges.map(({ node }) => (
                                            <li key={node.id}>
                                                <input
                                                    id={node.usesCategoryId}
                                                    value={node.usesCategoryId}
                                                    name="uses_category"
                                                    type="checkbox"
                                                    data-category-type="uses_category"
                                                    onChange={handleChange}
                                                    checked={checkedUsesCategories.includes(node.usesCategoryId)}
                                                />
                                                <label htmlFor={node.usesCategoryId}>{node.name}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </dd>
                            </div>
                            <div className="is-casestudy">
                                <dt id="casestudy-button">
                                    <button
                                        onClick={toggleList}
                                        data-category-type="casestudy_category"
                                    >
                                        <span className="is-text">ケーススタディ</span>
                                        <span className="is-icon"></span>
                                    </button>
                                </dt>
                                <dd id="casestudy-list">
                                    <ul>
                                        {data.allMicrocmsCasestudyCategory.edges.map(({ node }) => (
                                            <li key={node.id}>
                                                <input
                                                    id={node.casestudyCategoryId}
                                                    value={node.casestudyCategoryId}
                                                    name="casestudy_category"
                                                    type="checkbox"
                                                    data-category-type="casestudy_category"
                                                    onChange={handleChange}
                                                    checked={checkedCasestudyCategories.includes(node.casestudyCategoryId)}
                                                />
                                                <label htmlFor={node.casestudyCategoryId} onChange={handleChange}>{node.name}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div className="l-sidebar__button" id="js-sidebar-button">
                    <button className="l-sidebar__button__clear" onClick={clearCheckedCategories}>クリア</button>
                    <button className="l-sidebar__button__search" onClick={() => {
                        research();
                        hideDisplaySort();
                    }}>
                        <span className="is-text">検索</span>
                        <span className="is-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 0C3.14585 0 0 3.14585 0 7C0 10.8541 3.14585 14 7 14C8.748 14 10.345 13.348 11.5742 12.2812L12 12.707V14L18 20L20 18L14 12H12.707L12.2812 11.5742C13.348 10.345 14 8.748 14 7C14 3.14585 10.8541 0 7 0ZM7 2C9.77327 2 12 4.22673 12 7C12 9.77327 9.77327 12 7 12C4.22673 12 2 9.77327 2 7C2 4.22673 4.22673 2 7 2Z" fill="#FBFDFF"/>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
        </>
    )
}